/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import TitleWithLine from 'app/components/common/Typography/TitleWithLine';
import CreditCheckIcon from 'images/credit-card-check.svg';
import { P, S } from 'app/components/common/Typography';
import Checkbox from 'app/components/common/Checkbox';

import { useField, useFormikContext } from 'formik';
import Alert from 'app/components/common/Alert';
import WhiteButton from 'app/components/common/WhiteButton';
import CardInput from '../CardInput';
import { PaymentContext } from '../PaymentContext/context';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import {
  selectCurrentIntake,
  selectCurrentIntakeProduct,
  selectCustomer,
  selectCustomerShippingAddress,
} from 'app/selectors/customer';
import CardsIcons from 'images/payment/cards-icons.svg';
import { ShippingFields } from 'app/components/customer/steps/Shipping';
import Divider from '@setproduct-ui/core/Divider';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import { PrimaryButton } from 'app/components/common/Button';
import { fromJS } from 'immutable';
import * as actions from 'app/actions/customer';
import ApplePayButton from 'app/components/customer/steps/Payment/ApplePay/ApplePayButton';
import { isProduction } from 'app/helpers/env';
import createCheckoutHelper from 'app/helpers/createCheckoutHelper';
import * as selectors from 'app/selectors/customer';
import { NOOP } from 'app/actions/customer';

const cmdType = 'add_stripe_payment_method';

const GenericPaymentMethod = ({
  currentPaymentMethod,
  paymentErrorMessage,
  setOptInParams,
  onCheckout,
  disabled,
  priceCalculator,
  titleClassName = '',
  bodyClassName = 'payment_card',
  shippingFormValid,
  shippingFormRef,
  setUnavailableProductDialog,
  productPharmacyValid,
}) => {
  const [paymentMethod, setPaymentMethod] = useState(currentPaymentMethod);
  const dispatch = useAppDispatch();
  useEffect(() => {
    setPaymentMethod(currentPaymentMethod);
  }, [currentPaymentMethod]);
  const currentIntake = useAppSelector(selectCurrentIntake);

  const customer = useAppSelector(selectCustomer);
  const shippingAddress = useAppSelector(selectCustomerShippingAddress);

  const applePayEnabled = customer.get('apple_pay_enabled');
  const paymentContext = useContext(PaymentContext);
  const [expanded, _setExpanded] = useState(!currentPaymentMethod?.get('card_last4'));
  const [showAlert, setShowAlert] = useState(false);
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState(paymentErrorMessage);
  const [showApplePay, setShowApplePay] = useState(false);
  const [openModalPaymentMethod, setOpenModalPaymentMethod] = useState(false);
  const { handleSubmit, isSubmitting, submitCount, values } = useFormikContext<{
    full_name: string;
    city: string;
    state: string;
    address_line_1: string;
    address_line_2: string;
    postal_code: string;
  }>();

  const [field, , { setValue }] = useField('updateAddress');
  const billingDifferent = !!field.value;
  const errorRef = useRef<HTMLDivElement>(null);
  const intake = useAppSelector(selectCurrentIntake);
  const currentProductName = useAppSelector(selectCurrentIntakeProduct);
  const product = useAppSelector((state) => selectors.selectCustomerProduct(state, currentProductName));
  const checkoutHelper = createCheckoutHelper(intake, customer, product, true);
  const subProductName = checkoutHelper.subProductNameForIntakeCheckout(product);
  const isKingV2OralTrt = checkoutHelper.isKingV2OralTrt(subProductName);

  useEffect(() => {
    if (paymentErrorMessage) {
      setErrorMessage(paymentErrorMessage);
      errorRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else if (submitCount > 0) {
    }
  }, [paymentErrorMessage, submitCount]);

  const updatePaymentMethod = useCallback(async (result: any, payment_method_type: string) => {
    if (shippingFormRef.current?.dirty) {
      const response = (await shippingFormRef.current.submitForm()) as { type: string } | undefined;
      if (response?.type === NOOP) return;
    }

    setOptInParams({
      intake_name: currentIntake.get('name'),
      card_brand: result.cardBrand,
      card_last4: result.cardLastFour,
      payment_method_id: result.paymentMethodId,
    });
    if (currentPaymentMethod?.get('card_last4')) {
      const action = actions.apiRequestUserCommand({
        cmdType,
        params: {
          card_brand: result?.cardBrand,
          card_last4: result?.cardLastFour,
          payment_method_id: result?.paymentMethodId,
          payment_method_source: paymentContext?.paymentProvider,
          payment_method_type: payment_method_type,
        },
        context: {},
      });
      dispatch(action);
    }
    setPaymentMethod(() => fromJS({ card_brand: result.cardBrand, card_last4: result.cardLastFour }));
  }, []);

  const processApplePay = (result) => {
    if (result) {
      updatePaymentMethod(result, 'apple_pay').then(() => {
        onCheckout(result);
      });
    }
  };

  const onSubmit = useCallback(async () => {
    if (isKingV2OralTrt) {
      const customerState = shippingFormRef.current?.values?.state || shippingAddress.get('state');
      const kingV2AvailableStates = intake.get('king_v2_available_states');
      const validOralTrtState = kingV2AvailableStates.includes(customerState);
      if (!validOralTrtState) {
        setOpenModalPaymentMethod(false);
        setUnavailableProductDialog(!validOralTrtState);
        return;
      }
    }
    try {
      const full_name = `${customer.get('first_name')} ${customer.get('last_name')}`;
      const result = await paymentContext?.createPaymentMethod({ ...values, full_name });
      if (result) {
        await updatePaymentMethod(result, 'credit_card');
      }

      setShowAlert(true);
    } finally {
      setOpenModalPaymentMethod(false);
    }
  }, [expanded, currentIntake.get('name'), handleSubmit, paymentContext, setOptInParams, values]);

  const applePayButton = () => {
    if (window.ApplePaySession) {
      const merchantIdentifier = isProduction()
        ? 'merchant.com.maximustribe.app'
        : 'merchant.com.maximustribe.app.test';
      const promise = ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);

      promise
        .then((canMakePayments) => {
          setShowApplePay(canMakePayments);
        })
        .catch((error) => {
          console.error(error);
        });

      if (showApplePay) {
        return (
          <ApplePayButton
            aria-label="Pay with Apple Pay"
            onSuccessfulPayment={processApplePay}
            amount={priceCalculator.totalDueToday()}
            disabled={disabled}
          />
        );
      }
    }
  };

  const payWithCardButton = () => (
    <PrimaryButton
      className="mt24"
      type="button"
      data-testid="next-step"
      text="PAY WITH CREDIT CARD"
      onClick={() => setShowCheckoutForm(true)}
    />
  );

  return (
    <>
      <TitleWithLine className={`${titleClassName} mt52 mb16`} size="xl">
        Checkout
      </TitleWithLine>
      {showAlert && (
        <Alert onClose={() => setShowAlert(false)} className="mb24">
          Payment method updated successfully
        </Alert>
      )}
      {errorMessage && (
        <div ref={errorRef}>
          <Alert noClose type="error" className="mb24">
            {errorMessage}
          </Alert>
        </div>
      )}
      <div className={`${bodyClassName}`}>
        {applePayEnabled && applePayButton()}
        {showApplePay && !showCheckoutForm && payWithCardButton()}
        <div style={{ display: !showApplePay || showCheckoutForm ? 'block' : 'none' }}>
          {showApplePay && <Divider className="mt24" />}
          <div className="payment_method_info">
            {!!paymentMethod?.get('card_last4') && (
              <div className="flex">
                <div className="flex1">
                  <P>{paymentMethod?.get('card_brand')}:</P>
                  <div className="card_info">
                    <div>**** **** **** {paymentMethod?.get('card_last4')}</div>
                    <div>
                      <img src={CreditCheckIcon} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {expanded && <CardInput />}
            {!expanded && (
              <WhiteButton
                onClick={() => setOpenModalPaymentMethod(true)}
                className="payment_button mt24"
                data-testid="update-payment-method"
                text="update payment info"
                disabled={(!shippingAddress && !shippingFormValid) || !productPharmacyValid}
              />
            )}

            {expanded && (
              <div className="payment_method__accept">
                <div>We accept:</div>
                <div>
                  <img src={CardsIcons} />
                </div>
                <div>including HSA/FSA cards</div>
              </div>
            )}
            <hr />
            <div className="flex1">
              <Checkbox
                className="flexAlignCenter"
                label={<S small>Billing address different from shipping</S>}
                checked={billingDifferent}
                onChange={(e) => {
                  if (billingDifferent && !e.target.checked) {
                    setValue('updateAddress', false);
                  }
                  setValue(e.target.checked);
                }}
              />
            </div>
            {billingDifferent && (
              <>
                <Divider />
                <ShippingFields />
              </>
            )}
          </div>
          <PrimaryButton
            className="mt40"
            type="button"
            data-testid="next-step"
            text="CHECKOUT"
            onClick={onCheckout}
            disabled={disabled}
          />
        </div>
      </div>

      <SetProductDialog
        isOpen={openModalPaymentMethod}
        text={
          <div className="payment_card">
            <div className="payment_method_info">
              <CardInput />
              <div className="payment_method__accept">
                <div>
                  <img src={CardsIcons} />
                </div>
              </div>
              <P>including HSA/FSA cards</P>

              <hr />
              <div className="flex">
                <WhiteButton
                  onClick={() => setOpenModalPaymentMethod(false)}
                  data-testid="close-update-payment-method"
                  text="Cancel"
                />
                <PrimaryButton
                  data-testid="save-payment-method"
                  onClick={onSubmit}
                  text="Save"
                  disabled={isSubmitting || paymentContext?.invalid}
                />
              </div>
            </div>
          </div>
        }
        title="Payment Details"
        onClose={() => setOpenModalPaymentMethod(false)}
        className="manage_subscription__cancel_modal generic_payment_page__modal align-left maximized"
      />
    </>
  );
};

export default GenericPaymentMethod;
