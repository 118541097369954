import React from 'react';
import { CustomerUserImm, MagicianIntake, MagicianProduct } from 'app/types/admin/customerUser';
import { ImmutableMap } from 'app/types/admin';
import updateSubscriptionSteps from 'app/utils/updateSubscriptionSteps';
import BaseCheckoutHelper from 'app/components/customer/steps/Payment/Generic/BaseCheckoutHelper';
import MagicianProductSelectionForm from 'app/components/customer/steps/Payment/magician/MagicianProductSelectionForm';
import { AvailableProducts, MagicianSubProducts } from 'app/constants/Products';
import MagicianPriceCalculator from 'app/helpers/productSpecificPriceCalculators/magicianPriceCalculator';
import PriceCalculator from 'app/helpers/priceCalculator';
import { useAppSelector } from 'app/helpers/hooks';
import { selectMagicianDiagnoses2Attr, selectMagicianDiagnosesAttr } from 'app/selectors/customer';
import MagicianMultimonthPlanSelectionForm from 'app/components/customer/steps/Payment/magician/MagicianMultimonthPlanSelectionForm';

class MagicianCheckoutHelper extends BaseCheckoutHelper {
  intake: ImmutableMap<MagicianIntake>;
  product: ImmutableMap<MagicianProduct>;

  constructor(
    customer: CustomerUserImm,
    intake: ImmutableMap<MagicianIntake>,
    product: ImmutableMap<MagicianProduct>,
    isOnboarding: boolean,
  ) {
    super(customer, intake, isOnboarding);

    this.intake = intake;
    this.product = product;
  }

  currentProductName = () => AvailableProducts.Magician;

  firstStep = () => updateSubscriptionSteps.productStep;

  productSpecificInitialValues = () => {
    return {
      drug_variant: this.product?.get('opt_in_choice')?.get('drug_variant') || MagicianSubProducts.Semaglutide,
      lab_kit_type: this.customer.get('king_lab_kit_type'),
      multimonth_plan: this.product?.get('opt_in_choice')?.get('multimonth_plan') || 12,
    };
  };

  multimonthEnabled = () => !!this.intake.get('magician_multimonth_enabled');

  updateSubProductCommandName = 'select_magician_drug_variant';

  multimonthPlansOptions = [
    {
      value: 1,
      label: 'Monthly Plan',
    },
    {
      value: 3,
      label: 'Quarterly Plan',
    },
    {
      value: 6,
      label: 'Semiannual Plan',
    },
    {
      value: 12,
      label: 'Yearly Plan',
    },
  ];

  selectProductStepPath = () => 'sub_product_selection';

  renderProductStep = (firstTimeChoice: boolean = false) => (
    <MagicianProductSelectionForm firstTimeChoice={firstTimeChoice} />
  );

  renderMultimonthStep = (handleChangeProduct) => (
    <MagicianMultimonthPlanSelectionForm handleChangeProduct={handleChangeProduct} />
  );

  renderPlanSelection = (_onMultimonthUpdate, _subProductName) => null;

  priceCalculator = (values: any, subscription_paid: boolean = false, lab_paid: boolean = false) => {
    const magicianPriceCalculator = new MagicianPriceCalculator(
      values.include_supplement,
      this.supplementsOnAnotherIntake(),
      this.intake.get('name'),
      values.use_own_lab,
      values.multimonth_plan,
      values.drug_variant,
      this.isOnboarding,
      this.crlFeatureEnabledEncloPregWeightLoss(),
      subscription_paid,
      lab_paid,
    );

    return new PriceCalculator(magicianPriceCalculator, this.product.get('discounts'));
  };

  showLabKitOnboarding = () =>
    this.isOnboarding &&
    (useAppSelector(selectMagicianDiagnosesAttr('high_blood_pressure')) ||
      useAppSelector(selectMagicianDiagnoses2Attr('kidney_disease')));

  showChangeProductButton = () => true;

  subProductName = (values) => values.drug_variant;

  subProductNameForIntakeCheckout = () =>
    this.product?.get('opt_in_choice')?.get('drug_variant') || MagicianSubProducts.Semaglutide;

  useOwnLab = () => !!this.customer.get('magician_use_own_lab');
}

export default MagicianCheckoutHelper;
